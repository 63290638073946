import React, { useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import Modal, { ModalProps } from 'components/Modal';
import {  copyToClipboard} from 'utils/string';

interface ModalMemberSheetProps extends ModalProps {
  onSubmit: () => Promise<[]>;
  html: string;
  listingId: string;
}



const ModalMemberSheet: React.FC<ModalMemberSheetProps> = ({
  onSubmit,
  onClose,
  html,
  open,
  listingId,
  className,
  ...props
}) => {

  
  return (
    <StyledModalMemberSheet
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      {...props}>
      <div onClick={(e) => {copyToClipboard(e)}} dangerouslySetInnerHTML={{__html: html}} />
    </StyledModalMemberSheet>
  );
};

export default ModalMemberSheet;

const StyledModalMemberSheet = styled(Modal)`
  .peopleXp {
    padding: 0;width:100%;
  }
  .peopleXp tr:nth-child(odd){
    background-color:#dedede4a;
  }
  .peopleXp td{padding: 10px 0px;}
  .peopleXp span{cursor: pointer;
    color: #df1721;
    font-weight: bold;
  }
  .modal-content {
    min-width: 300px;
    font-size: 16px;
    line-height: 24px;
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  button.large, .dropdown-wide {
    width: 100%;
    margin-top: 16px;
  }

  .dropdown-wide > label, .attorney__input > label {
    font-size: 12px;
  }

  .sale-side__checkbox {
    margin-bottom: 16px;
  }
`;
