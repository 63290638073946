import { SITE_URL } from 'consts';
import keysToCamel from './keysToCamel';
import { isMlsListing } from './listing';
// import documentTypes from './documentTypes.json';

// renames keys to camelCase and adds url
export const mapListings = (listings): Listing[] => {
  const uniqueIds = new Set();
  const uniqueListings: Listing[] = [];

  if (!Array.isArray(listings)) return [];

  listings.forEach(item => {
    if (uniqueIds.has(item.id)) {
      // eslint-disable-next-line no-console
      console.warn(`[mapListings]: Duplicate listing id {${item.id}}`);
      return;
    }

    uniqueIds.add(item.id);
    const formattedItem = keysToCamel(item);
    uniqueListings.push({
      ...formattedItem,
      images: formattedItem.images || [],
      url:
        SITE_URL && isMlsListing(formattedItem.listingId)
          ? `${SITE_URL}/listing/${formattedItem.listingId}/`
          : null,
      address1: formattedItem.address1 || item.google_address,
      listingDate: item.listing_date ? new Date(item.listing_date * 1000) : null,
      publishDate: item.publish_date ? item.publish_date * 1000 : null,
      expirationDate: item.expiration_date ? new Date(item.expiration_date * 1000) : null,
      listingAgent: {
        ...formattedItem.listingAgent,
        email: formattedItem.listingAgent?.email?.split(';'),
        agency: formattedItem.listingOffice?.name || ''
      },
      seller: formattedItem.seller ? mapSellBuySide(formattedItem.seller) : null,
      buyer: formattedItem.buyer ? mapSellBuySide(formattedItem.buyer) : null,
      sellerBroker: formattedItem.sellerbroker ? mapSellBuySide(formattedItem.sellerbroker) : null,
      buyerBroker: formattedItem.buyerbroker ? mapSellBuySide(formattedItem.buyerbroker) : null,
      sellProcess: item.sell_process,
      offersCount: item.offersCount
    });
  });

  return uniqueListings;
};

export const mapListingDocuments = (documents): WorkspaceDocument[] => {
  return documents.map(item => ({
    id: item.id,
    name: item.filename,
    createdBy: item.created_by,
    createdOn: item.created_on * 1000,
    doctype: item.doctype_id,
    docname: item.docname,
    category: item.doctype_category,
    categoryOrder: item.category_order,
    folder: item.folder_id,
    listing: item.listing_id,
    link: item.link,
    draft: item.draft,
    needSign: item.need_sign,
    documentStatus: item.document_status,
    viewId: item.view_id,
    isExternal: Boolean(item.xp_doc),
    userSignatures: item.userSignatures,
    buyProcessId: item.buyProcessId,
    openSignatures: item.open_signatures,
    countSignatures: item.signature_count,
    actualSignatureUsers: item.actualSignatureUsers
  }));
};

export const mapDocumentsTree = (tree: any): AnyNode[] => {
  return tree.map(item => {
    let node: any = {
      id: String(item.id),
      type: item.type,
      active: Boolean(item.active),
      createdBy: item.created_by,
      createdOn: new Date(item.created_on * 1000)
    };
    if (item.type === 'folder')
      node = {
        ...node,
        name: item.folder,
        displayType: 'Folder',
        children: mapDocumentsTree(item.children)
      };
    else
      node = {
        ...node,
        name: item.filename,
        displayType: item.display_type,
        mimeType: item.mime_type,
        draft: Boolean(item.is_draft),
        doctype: item.doctype_id,
        listing: item.listing_id,
        size: item.size,
        signatureCount: item.signature_count
      };
    return node;
  });
};

export const mapWorkspaceNotes = (notes): WorkspaceNote[] =>
  notes.map(item => ({
    id: item.id,
    createdOn: item.created_on * 1000,
    name: item.name,
    email: item.created_by,
    text: item.note
  }));

export const mapWorkspaceActivityLog = items =>
  items.map(item => ({
    id: item.id,
    listingId: item.listing_id,
    createdOn: item.created_on * 1000,
    activityDate: item.activity_date * 1000,
    name: item.name,
    email: item.created_by,
    text: item.activity,
    type: item.type
  }));

export const mapWorkspaceOffer = (item): WorkspaceOffer => {
  return {
    id: item.id,
    buyProcessId: item.buy_process_id,
    active: item.active === 1,
    status: item.status,
    created: item.created,
    formData: item.data,
    offerAmount: item.offerAmount,
    name: item.name,
    link: item.link
  };
};

export const mapVendorOrder = (item): VendorOrder => {
  const documents = item.documents.map(item => ({
    ...keysToCamel(item),
    active: Boolean(item.active),
    createdOn: item.created_on * 1000,
    updatedOn: item?.updated_on * 1000
  }));
  return {
    ...keysToCamel(item),
    active: Boolean(item.active),
    createdOn: item.created_on * 1000,
    updatedOn: item.updated_on * 1000,
    deadline: item.deadline * 1000,
    offeredPrice: Number(item?.offered_price),
    documents
  };
};

export const mapVendorTask = (item): VendorTask => {
  return {
    ...keysToCamel(item),
    active: Boolean(item.active),
    done: Boolean(item.done),
    ordered: Boolean(item.ordered),
    createdOn: item.created_on * 1000,
    updatedOn: item?.updated_on * 1000,
    doneOn: item?.done_on * 1000,
    deadline: item?.deadline * 1000
  };
};

export const mapBroker = ({ id, name, email, phone }) => {
  return { id, name, email, phone };
};

const mapSellBuySide = data => ({
  name: data.name,
  email: data.email,
  phone: data.phone,
  businessPhone: data.businessPhone,
  contactPerson: data.contactPerson
});
