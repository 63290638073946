import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import Button from 'components/Button';
import Modal, { ModalProps } from 'components/Modal';
import { Input,Icon } from 'components';
import { useForm, Controller } from 'react-hook-form';
import iconTrash from 'assets/icons/trash.svg';

interface ModalAddTagProps extends ModalProps {
  onSubmit: () => Promise<[]>;
}

const ModalAddTag: React.FC<ModalAddTagProps> = ({
  onSubmit,
  onClose,
  open,
  className,
  children,
  ...props
}) => {

  const { control, handleSubmit } = useForm();

  return (
    <StyledModalAddTag
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      {...props}>
      {children}
   
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="tag"
          rules={{ required: 'Required' }}
          render={({ field, formState: { errors } }) => (
            <Input
              as="textarea"
              label="New tag"
              className="notearea"
              placeholder="New tag"
              error={errors.note?.message}
              data-cy="input_description"
              {...field}
            />
          )}
        />
        <Button type="submit" large>Add Tag</Button>
      </form>
    </StyledModalAddTag>
  );
};

export default ModalAddTag;

const StyledModalAddTag = styled(Modal)`
  .trash {
    border: none;
    width: 16px;
    height: 16px;
    background: url(${iconTrash}) no-repeat;
    background-size: contain;
    cursor: pointer;
    filter: grayscale(100%);
  }
  .notearea{width:100%;}
  .notecreateheader{margin:0;}
  .taskNotes {
    list-style:none;padding: 0;position:relative;
  }
  .taskNotes p {
    margin:0;line-height:normal;
  }
  .taskNotes li{
    padding: 5px;
    background: #f3f3f3;margin-bottom: 10px;position: relative;
  }
  .taskNotes span{font-size: 10px;color: grey;}
  .modal-content {
    min-width: 300px;
    max-width: 300px;
    font-size: 16px;
    line-height: 24px;
  }

  .taskNotes button{
    position: absolute;
    bottom: 8px;
    right: 8px;
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  button.large, .dropdown-wide {
    width: 100%;
    margin-top: 16px;
  }

  .dropdown-wide > label, .attorney__input > label {
    font-size: 12px;
  }

  .sale-side__checkbox {
    margin-bottom: 16px;
  }
`;
