import React, { useState } from 'react';
import styled from '@emotion/styled';

const AccordionMT = ({ getTemplateData,currentId,templateId,content, name }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <StyledAccordion>
        <div className="accordion-item">
      <div className="accordion-title" onClick={() => {setIsActive(!(templateId==currentId));getTemplateData(templateId)}}>
        <div>{name}</div>
        <div className='plusSignAcc'>{(templateId==currentId) ? '-' : '+'}</div>
      </div>
      {(templateId==currentId) && <div className="accordion-content" dangerouslySetInnerHTML={{__html: content}}></div>}
    </div>

        </StyledAccordion>
  )
    
};

export default AccordionMT;

const StyledAccordion = styled.div`
    .accordion-item{
        border: 1px solid #efefef;
        cursor:pointer;
    }
    .accordion-title>div{
        display: inline;font-size: 14px;
    }
    .accordion-title{padding: 15px 7px;background:#F1F1F1;}
    .accordion-content{padding: 15px 7px;cursor:text;font-size:12px !important;font-family:Arial !important;}
    .accordion-content span{font-size:14px !important;font-family:Arial !important;}
    .accordion-content p{font-size:14px !important;font-family:Arial !important;}
    .accordion-content b{font-size:14px !important;font-family:Arial !important;}
    .accordion-content div{font-size:14px !important;font-family:Arial !important;}
    .accordion-content strong{font-size:14px !important;font-family:Arial !important;}
    .plusSignAcc{ font-weight: bold;   float: right;font-style:bold;}
`