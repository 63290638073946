import React, { useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import Modal, { ModalProps } from 'components/Modal';
import { addXpMember } from 'workspace/api';
import notify from 'notify';

interface ModalXpMembersProps extends ModalProps {
  onSubmit: () => Promise<[]>;
  people: any;
  listingId: string;
}



const ModalXpMembers: React.FC<ModalXpMembersProps> = ({
  onSubmit,
  onClose,
  people,
  open,
  listingId,
  className,
  children,
  ...props
}) => {
  const [allPeople, setUpdateStates] = useState<any>(people);

  const addNewMemberByXp = async (listingId,people,index) => {
    try {
      await addXpMember({listingId,people});
      allPeople[index].needsNoRender = true;
      setUpdateStates({});
      setUpdateStates(allPeople);
      notify("Member synced");
    } catch (err) {
      notify("Could not add member");
    }
  };
  
  return (
    <StyledModalXpMembers
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      {...props}>
      {children}
      {allPeople && allPeople.length>0 &&
        <table className="peopleXp">
        {allPeople.map((row, index) =>
          <tr><td>{row.name}</td><td>{row.role_name}</td><td><span onClick={() => addNewMemberByXp(listingId,row,index)}>{'needsNoRender' in row ? "" : "+ import"}</span></td></tr>
        )}
      </table>
      }
      {!allPeople || allPeople.length == 0 &&
        <p>Nothing to import...</p>
      }
      
    </StyledModalXpMembers>
  );
};

export default ModalXpMembers;

const StyledModalXpMembers = styled(Modal)`
  .peopleXp {
    padding: 0;width:100%;
  }
  .peopleXp tr:nth-child(odd){
    background-color:#dedede4a;
  }
  .peopleXp td{padding: 10px 0px;}
  .peopleXp span{cursor: pointer;
    color: #df1721;
    font-weight: bold;
  }
  .modal-content {
    min-width: 300px;
    font-size: 16px;
    line-height: 24px;
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  button.large, .dropdown-wide {
    width: 100%;
    margin-top: 16px;
  }

  .dropdown-wide > label, .attorney__input > label {
    font-size: 12px;
  }

  .sale-side__checkbox {
    margin-bottom: 16px;
  }
`;
